<template>
  <Layout>
    <About/>
    <Feature/>
  </Layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
import Feature from '../Home/components/Feature.vue'

import About from './About.vue'
export default {
  components: { 
    Layout,
    About,
    Feature
  }
}
</script>
