import { createStore } from 'vuex'

// import modules from './modules'

const store = createStore({
  state: {
	  baseUrl: "https://smartpos.smart-node.net/api/",
    mediaUrl: "https://smartpos.smart-node.net/",
    // baseUrl: "http://localhost:5000/api/",
    // mediaUrl : "http://localhost:5000/"
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

