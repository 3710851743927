<template>
  <!-- End Client Section -->
  <section class="cs-bg" data-src="assets/img/feature_bg.svg">
    <div class="cs-height_95 cs-height_lg_70"></div>
    <div class="container">
      <div class="cs-seciton_heading cs-style1 text-center">
        <div class="cs-section_subtitle">{{$t("client.sub_title") }}</div>
        <div class="cs-height_10 cs-height_lg_10"></div>
        <h3 class="cs-section_title">{{$t("client.title") }}</h3>
      </div>
      <div class="cs-height_50 cs-height_lg_40"></div>
      <div class="cs-slider cs-style1 cs-gap-24">
        <div class="cs-slider_container" data-autoplay="0" data-loop="1" data-speed="600" data-fade-slide="0"  data-slides-per-view="responsive" data-xs-slides="2" data-sm-slides="3" data-md-slides="4" data-lg-slides="6" data-add-slides="6">
          <div class="cs-slider_wrapper">
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_1.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_2.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_3.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_4.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_5.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_6.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
            <div class="cs-slide">
              <div class="cs-client cs-accent_bg cs-center">
                <img src="assets/img/client_3.svg" alt="Client">
              </div>
            </div><!-- .cs-slide -->
          </div>
        </div><!-- .cs-slider_container -->
        <div class="cs-pagination cs-style1"></div>
      </div><!-- .cs-slider -->
    </div>
    <div class="cs-height_100 cs-height_lg_70"></div>
  </section>
  <!-- End Client Stores -->
</template>

<script>
export default {

}
</script>