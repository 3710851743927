<template>
  <Layout>
    <Hero />
    <Feature />
    <About />
    <Subscriptions />
    <Testimonials />
    <Client />
  </Layout>
</template>
  
<script>
import Layout from '@/components/mainLayout/Layout.vue';
import Client from './components/Client.vue';
import Testimonials from './components/Testimonials.vue';
import About from '../About/About.vue';
import Feature from './components/Feature.vue';
import Hero from './components/Hero.vue';
import Subscriptions from '../Subscriptions/Subscriptions.vue';
import Fun from './components/Fun.vue';

export default {
  components: {
    Layout,
    Client,
    Testimonials,
    About,
    Feature,
    Hero,
    Subscriptions,
    Fun
  }
}
</script>
  