<template>
  <div>
    <MainHeader />
      <slot />
    <MainFooter />
  </div>
</template>
<script>
import MainFooter from './MainFooter.vue';
import MainHeader from './MainHeader.vue';
export default {
    components : {
      MainHeader,
      MainFooter,
    }
}
</script>

<style>

</style>