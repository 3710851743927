<template>
  <!-- Start Fun Fact -->
  <div class="cs-height_135 cs-height_lg_0"></div>

  <div class="container-fluid">
    <div class="cs-funfact_1_wrap cs-type1">
      <div class="cs-funfact cs-style1">
        <div class="cs-funfact_icon cs-center"><img src="assets/img/icons/funfact_icon_1.svg" alt="Icon"></div>
        <div class="cs-funfact_right">
          <div class="cs-funfact_number cs-primary_font"><span data-count-to="320" class="odometer"></span>+</div>
          <h2 class="cs-funfact_title">{{$t("about.about_counter.stores")}}</h2>
        </div>
      </div>
      <div class="cs-funfact cs-style1">
        <div class="cs-funfact_icon cs-center"><img src="assets/img/icons/funfact_icon_2.svg" alt="Icon"></div>
        <div class="cs-funfact_right">
          <div class="cs-funfact_number cs-primary_font"><span data-count-to="92" class="odometer"></span>k</div>
          <h2 class="cs-funfact_title">{{$t("about.about_counter.customer")}}</h2>
        </div>
      </div>
      <div class="cs-funfact cs-style1">
        <div class="cs-funfact_icon cs-center"><img src="assets/img/icons/funfact_icon_3.svg" alt="Icon"></div>
        <div class="cs-funfact_right">
          <div class="cs-funfact_number cs-primary_font"><span data-count-to="5" class="odometer"></span>k</div>
          <h2 class="cs-funfact_title">{{$t("about.about_counter.rating")}}</h2>
        </div>
      </div>
      <div class="cs-funfact cs-style1">
        <div class="cs-funfact_icon cs-center"><img src="assets/img/icons/funfact_icon_4.svg" alt="Icon"></div>
        <div class="cs-funfact_right">
          <div class="cs-funfact_number cs-primary_font"><span data-count-to="20" class="odometer"></span>+</div>
          <h2 class="cs-funfact_title">{{$t("about.about_counter.award")}}</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- End Fun Fact -->
  <div class="cs-height_135 cs-height_lg_0"></div>
</template>

<script>
export default {

}
</script>