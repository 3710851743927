<template>
  <!-- Start About -->
  <section id="about" class="cs-gradient_bg_1">
    <div class="cs-height_100 cs-height_lg_70"></div>
    <div class="container">
      <div class="row align-items-center flex-column-reverse-lg">
        <div class="col-xl-6 wow" :class="[$i18n.locale == 'ar' ? 'fadeInRight' : 'fadeInLeft']" data-wow-duration="1s"
          data-wow-delay="0.3s">
          <div class="cs-left_full_width cs-space110">
            <div class="cs-left_sided_img">
              <img :src="services[serviceLocale][serviceName].about_section.img" alt="About">
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="cs-height_0 cs-height_lg_40"></div>
          <div class="cs-seciton_heading cs-style1">
            <div class="cs-section_subtitle">{{ services[serviceLocale][serviceName].about_section.title }}</div>
            <div class="cs-height_10 cs-height_lg_10"></div>
            <h3 class="cs-section_title">{{ services[serviceLocale][serviceName].about_section.title }} </h3>
          </div>
          <div class="cs-height_20 cs-height_lg_20"></div>
          <p>
            {{ services[serviceLocale][serviceName].about_section.description }}
          </p>
          <div class="cs-height_15 cs-height_lg_15"></div>
          <div class="cs-list_1_wrap">
            <ul class="cs-list cs-style1 cs-mp0">
              <li v-for="feature in services[serviceLocale][serviceName].about_section.features" :key="feature">
                <span class="cs-list_icon">
                  <img src="../../assets/img/icons/tick.svg" alt="Tick">
                </span>
                <div class="cs-list_right">
                  <p>{{ feature }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_100 cs-height_lg_70"></div>
  </section>
  <!-- End About -->

  <!-- Start All Feature -->
  <section class="cs-bg" data-src="assets/img/feature_bg.svg">
    <div id="feature">
      <div class="cs-height_95 cs-height_lg_70"></div>
      <div class="container">
        <div class="cs-seciton_heading cs-style1 text-center">
          <div class="cs-section_subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">{{
            $t("services.features_sub") }}</div>
          <div class="cs-height_10 cs-height_lg_10"></div>
          <h3 class="cs-section_title">{{ $t("services.features") }}</h3>
        </div>
        <div class="cs-height_50 cs-height_lg_40"></div>
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6 my-2" v-for="feature in services[serviceLocale][serviceName].features"
            :key="feature">
            <div class="cs-iconbox cs-style1 cs-type1">
              <div class="cs-iconbox_icon cs-center">
                <img src="../../assets/img/icons/icon_box_5.svg" alt="Icon">
              </div>
              <div class="cs-iconbox_in">
                <h3 class="cs-iconbox_title">{{ feature.title }}</h3>
                <div class="cs-iconbox_subtitle">{{ feature.description }}</div>
              </div>
            </div>
            <div class="cs-height_25 cs-height_lg_25"></div>
          </div>
        </div>
      </div>
      <div class="cs-height_75 cs-height_lg_45"></div>
    </div>
  </section>
  <!-- End All Feature -->

  <section id="faq" class="cs-gradient_bg_1">
    <div class="cs-height_95 cs-height_lg_70"></div>
    <div class="cs-seciton_heading cs-style1 text-center">
      <div class="cs-section_subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">{{
        $t("services.services_sub") }}</div>
      <div class="cs-height_10 cs-height_lg_10"></div>
      <h3 class="cs-section_title">{{ $t("services.services") }}</h3>
    </div>
    <div class="cs-height_50 cs-height_lg_40"></div>
    <div class="container">
      <div class="">
        <div class="cs-accordians cs-style1 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
          <!-- .cs-accordian -->
          <div class="cs-height_25 cs-height_lg_25"></div>
          <div class="cs-accordian cs-white_bg my-2" v-for="service in services[serviceLocale][serviceName].services"
            :key="service">
            <div class="cs-accordian_head">
              <h2 class="cs-accordian_title"><span></span> {{ service.title }}</h2>
              <span class="cs-accordian_toggle">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15 -7.36618e-07C12.0333 -8.82307e-07 9.13319 0.879733 6.66645 2.52795C4.19971 4.17617 2.27713 6.51885 1.14181 9.25975C0.00649787 12.0006 -0.290551 15.0166 0.288226 17.9264C0.867005 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16393 29.133 12.0736 29.7118C14.9834 30.2905 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3335C29.1203 20.8668 30 17.9667 30 15C29.9957 11.0231 28.414 7.21026 25.6019 4.39815C22.7897 1.58603 18.9769 0.00430081 15 -7.36618e-07V-7.36618e-07ZM15 20C14.085 20.0009 13.2014 19.6665 12.5163 19.06C12.1075 18.6962 11.72 18.3425 11.4663 18.0887L7.875 14.5587C7.75017 14.4457 7.64946 14.3086 7.57892 14.1557C7.50838 14.0028 7.46947 13.8372 7.46452 13.6689C7.45957 13.5005 7.48869 13.3329 7.55012 13.1762C7.61155 13.0194 7.70402 12.8766 7.822 12.7564C7.93998 12.6362 8.08102 12.5412 8.23667 12.4769C8.3923 12.4125 8.55934 12.3804 8.72773 12.3822C8.89612 12.3841 9.0624 12.4199 9.21659 12.4876C9.37078 12.5553 9.5097 12.6535 9.62501 12.7762L13.225 16.3125C13.46 16.5462 13.81 16.8637 14.1738 17.1875C14.4021 17.3889 14.6961 17.5001 15.0006 17.5001C15.3051 17.5001 15.5991 17.3889 15.8275 17.1875C16.19 16.865 16.54 16.5475 16.7675 16.3212L20.375 12.7762C20.4903 12.6535 20.6292 12.5553 20.7834 12.4876C20.9376 12.4199 21.1039 12.3841 21.2723 12.3822C21.4407 12.3804 21.6077 12.4125 21.7633 12.4769C21.919 12.5412 22.06 12.6362 22.178 12.7564C22.296 12.8766 22.3885 13.0194 22.4499 13.1762C22.5113 13.333 22.5404 13.5005 22.5355 13.6689C22.5305 13.8372 22.4916 14.0028 22.4211 14.1557C22.3505 14.3086 22.2498 14.4457 22.125 14.5587L18.5263 18.095C18.2763 18.345 17.8925 18.695 17.485 19.0562C16.8003 19.6647 15.916 20.0006 15 20Z"
                    fill="currentColor" />
                </svg>
              </span>
            </div>
            <div class="cs-accordian-body">
              <p class="py-3">
                {{ service.description }}
              </p>
              <div class="cs-list_1_wrap">
                <ul class="cs-list cs-style1 cs-mp0">
                  <li v-for="feature in service.features" :key="feature">
                    <span class="cs-list_icon">
                      <img src="../../assets/img/icons/tick.svg" alt="Tick">
                    </span>
                    <div class="cs-list_right">
                      <p> {{ feature }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div><!-- .cs-accordian -->
          <div class="cs-height_25 cs-height_lg_25"></div><!-- .cs-accordian -->
        </div>
      </div>
    </div>

    <div class="cs-height_100 cs-height_lg_70"></div>
  </section>
</template>

<script>
import services from "./services"

export default {
  data() {
    return {
      services: [],
    }
  },
  computed: {
    serviceName() {
      return this.$route.params.service_name
    },
    serviceLocale() {
      return this.$i18n.locale
    }
  },
  created() {
    this.services = services
  }
}
</script>