<template>
  <nav class="navbar py-4 fixed-top navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <router-link to="/">
        <img style="width: 130px" src="../../assets/img/logo.png" alt="Logo" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">{{ $t("menu.home") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/About" class="nav-link">{{ $t("menu.about") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/Subscriptions" class="nav-link">{{ $t("menu.subsciptions") }}</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ $t("menu.services") }}
            </a>
            <ul class="dropdown-menu shadow-lg">
              <li>
                <router-link to="/services/point-of-sales-for-pharmacy" class="dropdown-item" href="#">
                  {{ $t("menu.service_1") }}
                </router-link>
              </li>
              <li>
                <router-link to="/services/point-of-sale-for-stores" class="dropdown-item" href="#">
                  {{ $t("menu.service_2") }}
                </router-link>
              </li>
              <li>
                <router-link to="/services/point-of-sale-for-restaurants" class="dropdown-item" href="#">
                  {{ $t("menu.service_3") }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <div class="d-flex gap-1 d-sm-none cs-main_header_right">
              <div class="cs-toolbox">
                <a href="https://posowner.smart-node.net/login" target="_blank" class="cs-btn cs-color1">
                  <span>{{ $t('menu.login') }}</span>
                </a>
              </div>
              <div class="cs-toolbox">
                <a href="https://posowner.smart-node.net/register" target="_blank" class="cs-btn cs-color2">
                  <span>{{ $t('menu.button_text') }}</span>
                </a>
              </div>
              <div class="dropdown">
                <button class="dropdown-button dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span class="bx bx-world"></span>
                </button>
                <ul class="dropdown-menu shadow-lg" style="list-style: none">
                  <li @click="changeLang('ar')">
                    <a class="dropdown-item">
                      <div class="cursor-pointer d-flex gap-1">
                        <img width="20" height="20" src="../../assets/img/flags/arabic.png" alt="flag"
                          class="mr-2 flag-icon rounded-sm" />
                        <span class="align-middle">العربية</span>
                      </div>
                    </a>
                  </li>
                  <li @click="changeLang('en')">
                    <a class="dropdown-item">
                      <div class="cursor-pointer d-flex gap-1">
                        <img width="20" height="20" src="../../assets/img/flags/uk.png" alt="flag"
                          class="mr-2 flag-icon rounded-sm" />
                        <span class="align-middle">English</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div></div>
            </div>
          </li>
        </ul>
        <div class="d-none d-sm-flex gap-1 align-items-center cs-main_header_right">
          <div class="cs-toolbox">
            <a href="https://posowner.smart-node.net/register" target="_blank" class="cs-btn cs-color1">
              <span>{{ $t("menu.login") }}</span>
            </a>
          </div>
          <div class="cs-toolbox">
            <a href="https://posowner.smart-node.net/register" target="_blank" class="cs-btn cs-color2">
              <span>{{ $t("menu.button_text") }}</span>
            </a>
          </div>

          <div class="dropdown">
            <button class="dropdown-button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="bx bx-world"></span>
            </button>
            <ul style="list-style: none" class="dropdown-menu shadow-lg">
              <li @click="changeLang('ar')">
                <a class="dropdown-item">
                  <div class="cursor-pointer d-flex gap-1">
                    <img width="20" height="20" src="../../assets/img/flags/arabic.png" alt="flag"
                      class="mr-2 flag-icon rounded-sm" />
                    <span class="align-middle">العربية</span>
                  </div>
                </a>
              </li>
              <li @click="changeLang('en')">
                <a class="dropdown-item">
                  <div class="cursor-pointer d-flex gap-1">
                    <img width="20" height="20" src="../../assets/img/flags/uk.png" alt="flag"
                      class="mr-2 flag-icon rounded-sm" />
                    <span class="align-middle">English</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Header Section -->
</template>

<script>

export default {
  data() {
    return {
      current_lang: "ar",
    };
  },
  methods: {
    changeLang(lang) {
      this.current_lang = lang;
      console.log(this.current_lang);
      this.$i18n.locale = this.current_lang;
      localStorage.setItem("_lang", this.current_lang);
      let body = document.querySelector("body");
      if (this.$i18n.locale == "ar") {
        body.classList.add("ar");
        body.classList.remove("en");
      } else {
        body.classList.add("en");
        body.classList.remove("ar");
      }
    },
  },
  created() {
    this.current_lang = localStorage.getItem("_lang") || "ar";
  },
};
</script>

<style>
.navbar {
  padding: 0px 16px !important;
}

.dropdown-menu {
  border: none !important;
}

.flag-icon {
  border-radius: 100px !important;
  width: 25px !important;
}
</style>
