<template>
  <!-- Start Price Section -->
  <section class="cs-bg" data-src="assets/img/feature_bg.svg" id="pricing">
    <div class="cs-height_95 cs-height_lg_70"></div>
    <div class="cs-height_95 cs-height_lg_70"></div>
    <div class="container">
      <div class="cs-seciton_heading cs-style1 text-center">
        <div class="cs-section_subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">{{
          $t("subscriptions.sub_title") }}</div>
        <div class="cs-height_10 cs-height_lg_10"></div>
        <h3 class="cs-section_title">{{ $t("subscriptions.title") }}</h3>
      </div>
      <div class="cs-height_50 cs-height_lg_40"></div>
      <div class="card border border-0">
        <div class="card-body">
          <table>
            <thead>
              <tr>
                <th></th>
                <th v-for="subscription in packages" :key="subscription.id" class="text-dark">
                  <div class="cs-pricing_table cs-style1">
                    <div class="cs-pricing_head">
                      <div class="cs-pricing_heading">
                        <div class="cs-pricing_icon cs-center"><img src="../../assets/img/icons/pricing_icon_1.svg"
                            alt="Icon"></div>
                      </div>
                      <div class="cs-price cs-primary_font text-center">
                        <div class="p-2">
                          <h2 class="my-0 fw-normal cs-accent_color fw-bolder">{{ $i18n.locale == 'ar' ?
          subscription?.name : subscription?.name_en }}</h2>
                        </div>
                        <div class="card-title pricing-card-title">
                          <span class="h1 fw-bolder">${{ subscription?.monthly_price }}</span>
                          <small class="text-body-secondary h2 fw-bold">/{{ $t('subscriptions.monthly') }}</small>
                        </div>
                        <h4 class="card-title pricing-card-title">${{ subscription?.annual_price }}
                          <small class="text-body-secondary fw-light">/{{ $t('subscriptions.yearly') }}</small>
                        </h4>
                      </div>
                      <div class="cs-pricing_lable text-center">{{ $t('subscriptions.get_7_days_free_trial') }}</div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="feature in packagesFeatures" :key="feature.id">
                <td class="fw-bold">{{ $i18n.locale == 'ar' ? feature?.name : feature?.name_en }}</td>
                <td v-for="subscription_packages_feature in feature?.packages" :key="subscription_packages_feature.id"
                  class="text-center">
                  <i
                    :class="[subscription_packages_feature?.available ? 'bx bx-check text-success' : 'bx bx-x text-danger']"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="cs-height_75 cs-height_lg_45"></div>
    </div>
  </section>
  <!-- End Price Section -->
</template>

<script>
export default {
  data() {
    return {
      packages: [],
      packagesFeatures: [],
    }
  },
  methods: {
    async getPackages() {
      const resPackages = await this.http.get("subscription-packages")
      const resPackagesFeatures = await this.http.get("subscription-packages-features/subscription-packages")
      this.packages = resPackages.data
      this.packagesFeatures = resPackagesFeatures.data
    },
  },
  created() {
    this.getPackages()
  }
}
</script>

<style>
i {
  -webkit-text-stroke: 1px;
  font-size: 25px;
  font-style: unset !important;
}

td {
  font-size: 18px;
}

.card {
  background-color: #6990ffe3 !important;
}

.card-title.pricing-card-title {}
</style>