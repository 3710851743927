export default {
  "ar": {
    "point-of-sales-for-pharmacy": {
      "about_section": {
        "title": "نقاط البيع للصيدليات",
        "img": "https://ohiocapitaljournal.com/wp-content/uploads/2023/03/CVS-pharmacy-inside-Lynne-Terry-scaled.jpg",
        "description": "نحن مزودون رائدين لأنظمة نقاط البيع للصيدليات التي تم تصميمها لمساعدة الصيدليات بجميع الأحجام على التشغيل بشكل أكثر كفاءة وربحية. نظمنا سهلة الاستخدام ومليئة بالميزات التي يمكن أن تساعدك في تحسين خدمة العملاء وإدارة المخزون وتتبع المبيعات. نحن ندرك أن مجال الصيدلة متغيرة باستمرار، ونحن ملتزمون بتوفير أحدث حلول نقاط البيع المبتكرة والمحدثة لعملائنا. نظمنا متوافقة مع جميع اللوائح القابلة للتطبيق، ونقدم مجموعة متنوعة من خيارات الدعم لمساعدتك في الاستفادة القصوى من استثمارك.",
        "features": [
          "إدارة علاقات العملاء (CRM): تتبع تاريخ مشتريات العملاء وتفضيلاتهم وتعليقاتهم. استخدم بيانات CRM لإنشاء حملات تسويق مستهدفة وعروض ترويجية.",
          "إدارة المخزون: إدارة العناصر والكميات في المخزون، وتتبع حركة المخزون وتحديد مستويات السلامة، وطلب الكميات اللازمة من الموردين، وإنشاء تقارير المخزون.",
          "إدارة الروشتات: إدارة روشتات العملاء، بما في ذلك ملء الروشتات وتتبع حالة الروشتات وإدارة الإعادة."
          ,

        ]
      },
      "features": [
        {
          "title": "تقارير المبيعات الفورية",
          "icon": "",
          "description": "تعتبر تقارير المبيعات الفورية أداة قيمة لأي عمل يستخدم نظام نقاط البيع (POS). إنها توفر رؤى حول أداء عملك على أساس لحظي، مما يساعدك في اتخاذ قرارات مستنيرة بشأن مخزونك وعمالك واستراتيجيات التسويق."
        },
        {
          "title": "تتبع مستويات المخزون وإنشاء تقارير المخزون",
          "icon": "",
          "description": "تعتبر تقارير المبيعات الفورية أداة قيمة لأي عمل يستخدم نظام نقاط البيع (POS). إنها توفر رؤى حول أداء عملك على أساس لحظي، مما يساعدك في اتخاذ قرارات مستنيرة بشأن مخزونك وعمالك واستراتيجيات التسويق."
        },
        {
          "title": "نقطة بيع متنقلة",
          "icon": "",
          "description": "خذ الصيدلة الخاصة معك في اي مكان, من إدارة المخازن الى عمليات البيع من أي مكان مما يوفر المرونة و الراحة"
        },

      ],
      "services": [

        {
          "id": "2",
          "title": "تتبع مستويات المخزون في الوقت الحالي",
          "description": "يمكن لنظام نقاط البيع في الصيدلية مساعدتك في تتبع مستويات المخزون في الوقت الحالي. وهذا يعني أنه يمكنك دائمًا رؤية كمية كل منتج لديك في المتناول، دون الحاجة إلى عد الجرد يدويًا. يمكن أيضًا لنظام نقاط البيع في الصيدلية مساعدتك في إنشاء تقارير المخزون، مثل :",
          "features": [
            "تقرير حالة المخزون: يظهر لك هذا التقرير مستوى المخزون الحالي لكل منتج.",
            "تقرير نقطة إعادة الطلب: يحدد هذا التقرير المنتجات التي تقل الكمية المتاحة منها وتحتاج إلى إعادة الطلب.",
            "تقرير حركة المخزون: يظهر لك هذا التقرير حركة المنتجات داخل وخارج المخزون على مدى فترة زمنية.",
            "تقرير تقييم المخزون: يظهر لك هذا التقرير قيمة مخزونك في نقطة زمنية معينة."
          ]
        },
        {
          "id": "3",
          "title": "برنامج الولاء للعملاء",
          "description": "قم بدمج برنامج الولاء للعملاء بسلاسة في نظام نقاط البيع في الصيدلية الخاص بك. قم بمكافأة العملاء على وفائهم وتشجيعهم على إعادة الأعمال بترويجات مصممة خصيصًا.",
          "features": [
            "تجميع النقاط: السماح للعملاء بجمع النقاط مع كل عملية شراء.",
            "استرداد الجوائز: توفير خيارات للعملاء لاسترداد النقاط المكتسبة مقابل خصومات أو منتجات مجانية.",
            "مستويات الولاء المدرجة: إنشاء عدة مستويات من الولاء مع زيادة الفوائد للعملاء الذين ينفقون أكثر.",
            "عروض شخصية: تصميم ترويجات وخصومات استنادًا إلى تفضيلات العملاء الفردية وتاريخ الشراء."
          ]
        }
      ]
    },

    "point-of-sale-for-stores": {
      "about_section": {
        "title": "Point of Sale for Stores",
        "img": "https://images.unsplash.com/photo-1647427017067-8f33ccbae493?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cG9pbnQlMjBvZiUyMHNhbGV8ZW58MHx8MHx8fDA%3D",
        "description": "نحن مزوّدون رائدين في أنظمة نقاط البيع (POS) المصممة لمساعدة المتاجر بجميع الأحجام على العمل بشكل أكثر كفاءة وربحية. نظمنا سهلة الاستخدام ومليئة بالميزات التي يمكن أن تساعدك في تحسين خدمة العملاء وإدارة المخزون وتتبع المبيعات، وأكثر من ذلك. نحن ندرك أن صناعة التجزئة في تغيّر مستمر، ونحن ملتزمون بتوفير حلول نقاط البيع الأحدث والأكثر ابتكارًا لعملائنا. نظمنا متوافقة مع جميع اللوائح القابلة للتطبيق، ونقدم مجموعة متنوعة من خيارات الدعم لمساعدتك في الاستفادة القصوى من استثمارك.",
        "features": [
          "إدارة علاقات العملاء (CRM): تتبع تاريخ مشتريات العملاء وتفضيلاتهم وتعليقاتهم. استخدم بيانات CRM لإنشاء حملات تسويق مستهدفة وعروض ترويجية.",
          "إدارة المخزون: إدارة العناصر والكميات في المخزون، وتتبع حركة المخزون وتحديد مستويات السلامة، وطلب الكميات اللازمة من الموردين، وإنشاء تقارير المخزون.",

          "التقارير والتحليلات: إنشاء تقارير شاملة حول جميع جوانب عملك، بما في ذلك المبيعات والمخزون وأداء الموظفين وسلوك العملاء ."]
      },
      "features": [
        {
          "title": "تقارير المبيعات في الوقت الحالي",
          "icon": "",
          "description": "تعتبر تقارير المبيعات في الوقت الحالي أداة قيمة لأي عمل يستخدم نظام نقاط البيع (POS). توفر تلك التقارير رؤى حول أداء عملك على أساس لحظة بلحظة، مما يمكنك من اتخاذ قرارات مستنيرة بشأن مخزونك وطاقم العمل واستراتيجيات التسويق."
        },
        {
          "title": "تتبع مستويات المخزون وإنشاء تقارير المخزون",
          "icon": "",
          "description": "تتبع مستويات المخزون وإنشاء تقارير المخزون ضروري لأي متجر. يتيح لك ذلك التأكد من وجود ما يكفي من المنتجات المناسبة لتلبية احتياجات عملائك، مع تجنب التخزين الزائد والفاقد."
        }
      ],
      "services": [
        {
          "title": "تتبع مستويات المخزون في الوقت الحالي",
          "description": "يمكن لنظام نقاط البيع في المتجر مساعدتك في تتبع مستويات المخزون في الوقت الحالي. وهذا يعني أنه يمكنك دائمًا رؤية كمية كل منتج لديك في المخزون، دون الحاجة إلى عد المخزون يدويًا. يمكن أيضًا لأنظمة نقاط البيع في المتجر مساعدتك في إنشاء تقارير المخزون، مثل:",
          "features": [
            "تقرير حالة المخزون: يظهر لك هذا التقرير مستوى المخزون الحالي لكل منتج.",
            "تقرير نقطة إعادة الطلب: يحدد هذا التقرير المنتجات التي تقل الكمية فيها وتحتاج إلى إعادة الطلب.",
            "تقرير حركة المخزون: يظهر لك هذا التقرير حركة المنتجات داخل وخارج المخزون خلال فترة زمنية.",
            "تقرير تقييم المخزون: يظهر لك هذا التقرير قيمة المخزون الخاص بك في نقطة زمنية معينة."
          ]
        },
        {
          "title": "معالجة المبيعات بكفاءة",
          "description": "قم بتبسيط عمليات المبيعات الخاصة بك مع نظام نقاط البيع لدينا، مما يضمن معالجة المعاملات بشكل سريع ودقيق. من مسح المنتجات إلى الدفع، يتم تصميم نظامنا للكفاءة.",
          "features": [
            "مسح المنتجات بسرعة ودقة",
            "خيارات دفع متعددة (نقداً، بطاقات الائتمان، دفعات الهاتف المحمول)",
            "معالجة أوامر البيع وإصدار الفواتير",
            "إنشاء إيصالات تلقائية"
          ]
        },
        {
          "title": "برامج الولاء للعملاء",
          "description": "زيادة الاحتفاظ بالعملاء ورضاهم من خلال تنفيذ برامج الولاء من خلال نظام نقاط البيع لدينا. قم بمكافأة عملائك على تكرار تعاملهم وتشجيعهم على المشتريات المستقبلية.",
          "features": [
            "نظام نقاط الولاء قابل للتخصيص",
            "خصومات خاصة للعملاء المخلصين",
            "عروض ترويجية وصفقات حصرية",
            "تحليل بيانات العملاء للترويج المستهدف"
          ]
        },

      ]

    },

    "point-of-sale-for-restaurants": {
      "about_section": {
        "title": "Point of Sale for Restaurants",
        "img": "https://multitouchtables.net/wp-content/uploads/2018/11/pos-slide.jpg",
        "description": "نحن مزودون رائدين في أنظمة نقاط البيع (POS) التي تم تصميمها لمساعدة المطاعم بجميع الأحجام على تشغيل عملياتها بشكل أكثر كفاءة وربحًا. نظمنا سهلة الاستخدام ومليئة بالميزات التي يمكن أن تساعدك في تحسين خدمة العملاء وإدارة المخزون وتتبع المبيعات وغيرها. نحن ندرك أن صناعة المطاعم تتغير باستمرار، ونحن ملتزمون بتوفير أحدث وأكثر حلاول POS الابتكارية المتاحة لعملائنا. تتوافق نظمنا مع جميع اللوائح السارية، ونقدم مجموعة من خيارات الدعم لمساعدتك في الاستفادة القصوى من استثمارك.",
        "features": [
          "إدارة العلاقات مع العملاء (CRM): تتبع تاريخ شراء العملاء وتفضيلاتهم وتعليقاتهم. استخدم بيانات CRM لإنشاء حملات تسويق مستهدفة وعروض ترويجية.",
          "إدارة المخزون: إدارة العناصر والكميات في المخزون، وتتبع حركة المخزون وتحديد مستويات السلامة، وطلب الكميات اللازمة من الموردين، وإنشاء تقارير المخزون.",
          "وظائف نقاط البيع (POS): معالجة المبيعات، وقبول الدفعات، وطباعة الإيصالات.",
          "إدارة الموظفين: تتبع ساعات العمل وأداء الموظفين والعمولات.",
          "التقارير والتحليلات: إنشاء تقارير شاملة حول جميع جوانب عملك، بما في ذلك المبيعات والمخزون وأداء الموظفين وسلوك العملاء.",
          "إدارة الطوابق/القاعات: إدارة مخططات الطوابق/القاعات والطاولات والحجوزات."

        ]
      },
      "features": [
        {
          "title": "تقارير المبيعات الفورية",
          "icon": "",
          "description": "تقارير المبيعات الفورية هي أداة قيمة لأي نشاط تجاري يستخدم نظام نقاط البيع (POS). توفر رؤى حية حول أداء عملك على أساس لحظي، والتي يمكن أن تساعدك في اتخاذ قرارات مستنيرة حول المخزون والعمالة واستراتيجيات التسويق."
        },
        {
          "title": "إدارة الموظفين",
          "description": "قم بتحسين إدارة قوى العمل باستخدام ميزات نظام نقاط البيع (POS) لدينا. تتيح لك تتبع ساعات العمل بسهولة، ومراقبة الأداء، وتسهيل عمليات المرتبات.",
          "features": [
            "تتبع ساعات العمل وحضور الموظفين",
            "تحليل أداء المبيعات لكل موظف",
            "جدولة وإدارة شيفتات الموظفين",
            "معالجة المرتبات المتكاملة"
          ]
        },
        {
          "title": "نظام عرض المطبخ (KDS).",
          "icon": "",
          "description": "أرسل الطلبات مباشرة من نظام نقاط البيع الخاص بك إلى المطبخ، لتحضير الطعام بسرعة وكفاءة."
        },
        {
          "title": "إدارة الطوابق/القاعات",
          "icon": "",
          "description": "تتيح ميزات إدارة الطوابق/القاعات لك إدارة مخطط الطوابق/القاعات والطاولات والحجوزات في مطعمك. يمكن أن يساعد ذلك في تحسين قدرتك على استيعاب الجلوس وتحسين خدمة العملاء."
        }
      ],


      "services": [
        {
          "title": "تتبع مستويات المخزون في الوقت الحقيقي",
          "description": "يمكن لنظام نقاط البيع (POS) في المطاعم مساعدتك في تتبع مستويات المخزون في الوقت الحقيقي. وهذا يعني أنه يمكنك دائمًا رؤية كمية كل مكون تمتلكها دون الحاجة إلى عد المخزون يدويًا. يمكن أيضًا لنظام نقاط البيع في المطاعم مساعدتك في إنشاء تقارير المخزون، مثل:",
          "features": [
            "تقرير حالة المخزون: يظهر لك هذا التقرير المستوى الحالي للمكونات.",
            "تقرير نقطة إعادة الطلب: يحدد هذا التقرير المكونات التي تقل الكمية المتاحة منها وتحتاج إلى إعادة الطلب.",
            "تقرير حركة المخزون: يظهر لك هذا التقرير حركة المكونات داخل المخزون وخارجه على مدار فترة زمنية.",
            "تقرير تقييم المخزون: يظهر لك هذا التقرير قيمة المخزون الخاص بك في نقطة زمنية معينة."
          ]
        },
        {
          "title": "إدارة الطلبات والطاولات",
          "description": "قم بإدارة طلبات العملاء وحجوزات الطاولة بكفاءة باستخدام نظام نقاط البيع (POS) الخاص بنا.",
          "features": [
            "تتبع الطلبات: تتبع جميع طلبات العملاء من الطلب حتى التسليم.",
            "إدارة حجوزات الطاولة: إدارة وتتبع حجوزات الطاولة بسهولة لتحسين الخدمة.",
            "تخصيص الطلبات: السماح للعملاء بتخصيص طلباتهم حسب تفضيلاتهم.",
            "تاريخ الطلبات: الوصول إلى تاريخ الطلبات المفصل لفهم تفضيلات العملاء والتحليلات."
          ]
        },
        {
          "title": "حلول الدفع المتكاملة",
          "description": "بسط عملية معالجة الدفع مع حلول الدفع المتكاملة لتوفير تجربة عملاء سلسة.",
          "features": [
            "معالجة الدفع الآمنة: ضمان أمان معاملات العملاء باستخدام طرق الدفع المشفرة.",
            "خيارات دفع متعددة: قبول طرق دفع متنوعة، بما في ذلك بطاقات الائتمان والدفع الجوال وغيرها.",
            "دفع لاسلكي: توفير خيارات دفع لاسلكية للعملاء لتوفير الراحة.",
            "معالجة استرداد الأموال وإلغاء المعاملات: التعامل بسهولة مع استرداد الأموال وإلغاء المعاملات باستخدام حلول الدفع المتكاملة لدينا."
          ]
        }
      ]


    }
  },
  "en": {
    "point-of-sales-for-pharmacy": {
      "about_section": {
        "title": "Point Of sales for Pharmacies",
        "img": "https://ohiocapitaljournal.com/wp-content/uploads/2023/03/CVS-pharmacy-inside-Lynne-Terry-scaled.jpg",
        "description": "We are a leading provider of pharmacy POS systems that are designed to help pharmacies of all sizes run more efficiently and profitably. Our systems are easy to use and packed with features that can help you improve customer service, manage inventory, and track sales. We understand that the pharmacy industry is constantly changing, and we are committed to providing our customers with the most up-to-date and innovative POS solutions available. Our systems are compliant with all applicable regulations, and we offer a variety of support options to help you get the most out of your investment.",
        "features": [
          "Customer relationship management (CRM): Track customer purchase history, preferences, and feedback. Use CRM data to create targeted marketing campaigns and promotions."
          ,
          "Inventory management: Manage items and quantities in inventory, track inventory movement and determine safety levels, order the necessary quantities from suppliers, and generate inventory reports."
          ,
          "Prescription management: Manage customer prescriptions, including filling prescriptions, tracking prescription status, and managing refills."
          ,

        ]
      },
      "features": [
        {
          "title": "Real-time Sales Reports",
          "icon": "",
          "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
        },
        {
          "title": "Track inventory levels and generate inventory reports",
          "icon": "",
          "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
        },
        {
          "title": "Mobile POS Capabilities",
          "icon": "",
          "description": "Take your pharmacy on-the-go with mobile POS capabilities. Process sales, accept payments, and manage inventory from anywhere, providing flexibility and convenience."
        },

      ],
      "services": [


        {
          "id": "2",
          "title": "Track inventory levels in real time",
          "description": "A pharmacy POS system can help you track inventory levels in real time. This means that you can always see how much of each product you have on hand, without having to manually count your inventory. Pharmacy POS systems can also help you generate inventory reports, such as:",
          "features": [
            "Stock status report: This report shows you the current stock level of each product.",
            "Reorder point report: This report identifies the products that are running low and need to be reordered.",
            "Inventory movement report: This report shows you the movement of products into and out of your inventory over a period of time.",
            "Inventory valuation report: This report shows you the value of your inventory at a given point in time"
          ]
        },
        {
          "id": "3",
          "title": "Customer Loyalty Program Integration",
          "description": "Integrate a customer loyalty program seamlessly into your pharmacy POS system. Reward customers for their loyalty and encourage repeat business with tailored promotions.",
          "features": [
            "Points accrual: Allow customers to earn points with each purchase.",
            "Rewards redemption: Provide customers with options to redeem earned points for discounts or free products.",
            "Tiered loyalty levels: Establish multiple loyalty tiers with increasing benefits for higher-spending customers.",
            "Personalized offers: Tailor promotions and discounts based on individual customer preferences and purchase history."
          ]
        }
      ]
    },

    "point-of-sale-for-stores": {
      "about_section": {
        "title": "Point of Sale for Stores",
        "img": "https://images.unsplash.com/photo-1647427017067-8f33ccbae493?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cG9pbnQlMjBvZiUyMHNhbGV8ZW58MHx8MHx8fDA%3D",
        "description": "We are a leading provider of point of sale (POS) systems that are designed to help stores of all sizes run more efficiently and profitably. Our systems are easy to use and packed with features that can help you improve customer service, manage inventory, track sales, and more. We understand that the retail industry is constantly changing, and we are committed to providing our customers with the most up-to-date and innovative POS solutions available. Our systems are compliant with all applicable regulations, and we offer a variety of support options to help you get the most out of your investment.",
        "features": [
          "Customer relationship management (CRM): Track customer purchase history, preferences, and feedback. Use CRM data to create targeted marketing campaigns and promotions.",
          "Inventory management: Manage items and quantities in inventory, track inventory movement and determine safety levels, order the necessary quantities from suppliers, and generate inventory reports.",

          "Reporting and analytics: Generate comprehensive reports on all aspects of your business, including sales, inventory, employee performance, and customer behavior."
        ]
      },
      "features": [
        {
          "title": "Real-time sales reports",
          "icon": "",
          "description": "Real-time sales reports are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
        },
        {
          "title": "Track inventory levels and generate inventory reports",
          "icon": "",
          "description": "Tracking inventory levels and generating inventory reports are essential for any store. It allows you to ensure that you have enough of the right products on hand to meet the needs of your customers, while also avoiding overstocking and waste."
        }
      ],
      "services": [
        {
          "title": "Track inventory levels in real time",
          "description": "A store POS system can help you track inventory levels in real time. This means that you can always see how much of each product you have on hand, without having to manually count your inventory. Store POS systems can also help you generate inventory reports, such as:",
          "features": [
            "Stock status report: This report shows you the current stock level of each product.",
            "Reorder point report: This report identifies the products that are running low and need to be reordered.",
            "Inventory movement report: This report shows you the movement of products into and out of your inventory over a period of time.",
            "Inventory valuation report: This report shows you the value of your inventory at a given point in time."
          ]
        },
        {
          "title": "Efficient Sales Processing",
          "description": "Streamline your sales processes with our POS system, ensuring fast and accurate transaction processing. From product scanning to payment, our system is designed for efficiency.",
          "features": [
            "Quick and accurate product scanning",
            "Multiple payment options (cash, credit cards, mobile payments)",
            "Sales order processing and invoicing",
            "Automated receipt generation"
          ]
        },
        {
          "title": "Customer Loyalty Programs",
          "description": "Enhance customer retention and satisfaction by implementing loyalty programs through our POS system. Reward your customers for their repeat business and encourage future purchases.",
          "features": [
            "Customizable loyalty point system",
            "Special discounts for loyal customers",
            "Promotional offers and exclusive deals",
            "Customer data analysis for targeted promotions"
          ]
        },

      ]

    },

    "point-of-sale-for-restaurants": {
      "about_section": {
        "title": "Point of Sale for Restaurants",
        "img": "https://multitouchtables.net/wp-content/uploads/2018/11/pos-slide.jpg",
        "description": "We are a leading provider of point of sale (POS) systems that are designed to help restaurants of all sizes run more efficiently and profitably. Our systems are easy to use and packed with features that can help you improve customer service, manage inventory, track sales, and more. We understand that the restaurant industry is constantly changing, and we are committed to providing our customers with the most up-to-date and innovative POS solutions available. Our systems are compliant with all applicable regulations, and we offer a variety of support options to help you get the most out of your investment.",
        "features": [
          "Customer relationship management (CRM): Track customer purchase history, preferences, and feedback. Use CRM data to create targeted marketing campaigns and promotions.",
          "Inventory management: Manage items and quantities in inventory, track inventory movement and determine safety levels, order the necessary quantities from suppliers, and generate inventory reports.",
          "Point-of-sale (POS) functionality: Process sales, accept payments, and print receipts.",
          "Employee management: Track employee hours, performance, and commissions.",
          "Reporting and analytics: Generate comprehensive reports on all aspects of your business, including sales, inventory, employee performance, and customer behavior.",
          "Floors/Halls Management: Manage floorplans, tables, and reservations.",
        ]
      },
      "features": [
        {
          "title": "Real-time sales reports",
          "icon": "",
          "description": "Real-time sales reports are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
        },
        {
          "title": "Employee Management",
          "description": "Optimize your workforce with our POS system's employee management features. Easily track working hours, monitor performance, and streamline payroll processes.",
          "features": [
            "Time clock and attendance tracking",
            "Sales performance analysis for each staff member",
            "Employee scheduling and shift management",
            "Integrated payroll processing"
          ]
        },
        {
          "title": "Kitchen display system (KDS).",
          "icon": "",
          "description": "Send orders from your POS system directly to your kitchen, so food can be prepared quickly and efficiently."
        },
        {
          "title": "Floors/Halls Management",
          "icon": "",
          "description": "Floors/Halls management features allow you to manage your restaurant's floorplan, tables, and reservations. This can help you to optimize your seating capacity and improve customer service."
        },

      ],
      "services": [
        {
          "title": "Track inventory levels in real time",
          "description": "A restaurant POS system can help you track inventory levels in real time. This means that you can always see how much of each ingredient you have on hand, without having to manually count your inventory. Restaurant POS systems can also help you generate inventory reports, such as:",
          "features": [
            "Stock status report: This report shows you the current stock level of each ingredient.",
            "Reorder point report: This report identifies the ingredients that are running low and need to be reordered.",
            "Inventory movement report: This report shows you the movement of ingredients into and out of your inventory over a period of time.",
            "Inventory valuation report: This report shows you the value of your inventory at a given point in time."
          ]
        },
        {
          "title": "Order and Table Management",
          "description": "Efficiently manage customer orders and table reservations with our POS system.",
          "features": [
            "Order tracking: Keep track of all customer orders from placement to delivery.",
            "Table reservation management: Easily manage and track table reservations for better service.",
            "Order customization: Allow customers to customize their orders to meet their preferences.",
            "Order history: Access detailed order history for customer preferences and insights."
          ]
        },
        {
          "title": "Integrated Payment Solutions",
          "description": "Streamline payment processing with integrated payment solutions for a seamless customer experience.",
          "features": [
            "Secure payment processing: Ensure the security of customer transactions with encrypted payment methods.",
            "Multiple payment options: Accept various payment methods, including credit cards, mobile payments, and more.",
            "Contactless payments: Provide customers with the convenience of contactless payment options.",
            "Refund and void processing: Easily handle refunds and void transactions with our integrated payment solutions."
          ]
        }
      ]
    }
  }
}
