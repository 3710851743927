<template>
  <layout>
    <Subscriptions/>
  </layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
import Subscriptions from '../Subscriptions/Subscriptions.vue'

export default {
  components: { 
    Layout, 
    Subscriptions
  },
}
</script>