<template>
  <!-- Start Hero -->
  <div id="home">
      <div class="cs-height_80 cs-height_lg_80"></div>
      <section class="cs-hero cs-style1 cs-bg" data-src="../../../assets/img/hero_bg.svg">
        <div class="container">
          <div class="cs-hero_img">
            <div class="cs-hero_img_bg cs-bg" data-src="../../../assets/img/hero_img_bg.png"></div>
            <img src="../../../assets/img/hero_pos.avif" alt="Smart POS" class="wow" :class="[$i18n.locale == 'ar' ? 'fadeInLeft' : 'fadeInRight']" data-wow-duration="1s" data-wow-delay="0.4s">
          </div>
          <div class="cs-hero_text">
            <div class="cs-hero_secondary_title">{{$t("hero.sub_title")}}
            </div>
            <h1 class="cs-hero_title">{{$t("hero.title1")}}<br>{{$t("hero.title2")}}</h1>
            <div class="cs-hero_subtitle">{{$t("hero.descr1")}} <br>{{$t("hero.descr2")}}</div>
            <a href="#" class="cs-btn"><span>{{$t("hero.button_text")}}</span></a>
          </div>
          <div class="cs-hero_shapes">
            <div class="cs-shape cs-shape_position1">
              <img src="../../../assets/img/shape/shape_1.svg" alt="Shape">
            </div>
            <div class="cs-shape cs-shape_position2">
              <img src="../../../assets/img/shape/shape_2.svg" alt="Shape">
            </div>
            <div class="cs-shape cs-shape_position3">
              <img src="../../../assets/img/shape/shape_3.svg" alt="Shape">
            </div>
            <div class="cs-shape cs-shape_position4">
              <img src="../../../assets/img/shape/shape_4.svg" alt="Shape">
            </div>
            <div class="cs-shape cs-shape_position5">
              <img src="../../../assets/img/shape/shape_5.svg" alt="Shape">
            </div>
            <div class="cs-shape cs-shape_position6">
              <img src="../../../assets/img/shape/shape_6.svg" alt="Shape">
            </div>
          </div>
        </div>
      </section>
  </div>
  <!-- End Hero -->
</template>

<script>
export default {

}
</script>