<template>
  <!-- Start About -->
  <section id="about" class="cs-gradient_bg_1">
    <div class="cs-height_100 cs-height_lg_70"></div>
    <div class="container">
      <div class="row align-items-center flex-column-reverse-lg">
        <div class="col-xl-6 wow" :class="[$i18n.locale == 'ar' ? 'fadeInRight' : 'fadeInLeft']" data-wow-duration="1s"
          data-wow-delay="0.3s">
          <div class="cs-left_full_width cs-space110">
            <div class="cs-left_sided_img">
              <img
                src="https://images.unsplash.com/photo-1556740772-1a741367b93e?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTR8fHBvaW50JTIwb2YlMjBzYWxlfGVufDB8fDB8fHww"
                alt="About">
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="cs-height_0 cs-height_lg_40"></div>
          <div class="cs-seciton_heading cs-style1">
            <div class="cs-section_subtitle">{{ $t("about.about_section.sub_title") }}</div>
            <div class="cs-height_10 cs-height_lg_10"></div>
            <h3 class="cs-section_title">{{ $t("about.about_section.title") }} </h3>
          </div>
          <div class="cs-height_20 cs-height_lg_20"></div>
          <p>
            {{ $t("about.about_section.descr") }}
          </p>
          <div class="cs-height_15 cs-height_lg_15"></div>
          <div class="cs-list_1_wrap">
            <ul class="cs-list cs-style1 cs-mp0">
              <li>
                <span class="cs-list_icon">
                  <img src="assets/img/icons/tick.svg" alt="Tick">
                </span>
                <div class="cs-list_right">
                  <h3>{{ $t("about.about_section.section_title_1") }}</h3>
                  <p>{{ $t("about.about_section.section_descr_1") }}</p>
                </div>
              </li>
              <li>
                <span class="cs-list_icon">
                  <img src="assets/img/icons/tick.svg" alt="Tick">
                </span>
                <div class="cs-list_right">
                  <h3>{{ $t("about.about_section.section_title_2") }}</h3>
                  <p>{{ $t("about.about_section.section_descr_2") }}</p>
                </div>
              </li>
            </ul>
            <div class="cs-list_img wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
              <img
                src="https://images.unsplash.com/photo-1556742521-9713bf272865?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Njh8fHBvaW50JTIwb2YlMjBzYWxlfGVufDB8fDB8fHww"
                alt="About">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_100 cs-height_lg_70"></div>
  </section>
  <!-- End About -->
  <Feature />
</template>

<script>
export default {
  components: {

  }
}
</script>