<template>
  <Layout>
    <Contact/>
  </Layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
import Contact from './Contact.vue'
export default {
  components: {
    Layout,
    Contact
  }
}
</script>